import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'overview',
                    component: () => import('@/views/trackers/Location.vue'),
                },
                {
                    path: '/onboard',
                    component: () => import('@/views/trackers/Location.vue'),
                },
                {
                    path: '/report',
                    component: () => import('@/views/reporting/Generate.vue'),
                },
                {
                    path: '/billing',
                    component: () => import('@/views/access/Billing.vue'),
                },
                {
                    path: '/users',
                    component: () => import('@/views/users/Management.vue'),
                },
                {
                    path: '/equipment',
                    component: () => import('@/views/trackers/Location.vue'),
                },
                {
                    path: '/management',
                    component: () => import('@/views/management/Wrapper.vue'),
                    children: [
                        {
                            name: 'Overview',
                            path: '/management/overview',
                            component: () => import('@/views/management/Overview.vue')
                        },
                        {
                            name: 'Trackers',
                            path: '/management/trackers',
                            component: () => import('@/views/management/Trackers.vue')
                        },
                        {
                            name: 'Assets',
                            path: '/management/assets',
                            component: () => import('@/views/management/Assets.vue')
                        },
                        {
                            name: 'Sites',
                            path: '/management/sites',
                            component: () => import('@/views/management/Sites.vue')
                        },
                        {
                            name: 'Users',
                            path: '/management/users',
                            component: () => import('@/views/management/Users.vue')
                        },
                        {
                            name: 'Permissions',
                            path: '/management/access',
                            component: () => import('@/views/management/Access.vue')
                        },
                    ],
                },
                {
                    path: '/assets',
                    children: [
                        {
                            path: '/assets/branches',
                            name: 'asset-branches',
                            component: () => import('@/views/assets/Onboard.vue')
                        },
                        {
                            path: '/assets/onboard',
                            name: 'asset-onboard',
                            component: () => import('@/views/assets/Onboard.vue')
                        },
                    ]
                },
            ]
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/auth/Login.vue')
        },
        {
            path: '/recover',
            name: 'Recover',
            component: () => import('@/views/auth/Recover.vue')
        },
    ]
});

export default router;
