import type { Device } from "@/types";
import type { MySubClassedDexie } from "@/db";


const locPool = [
  {
    lat: "-37.91091870680206",
    lon: "144.98599991083867"
  },
  {
    lat: "-37.840935",
    lon: "144.946457"
  },
  {
    lat: "-37.85782055054145",
    lon: "145.038559461274"
  },
  {
    // Horsham
    lat: "-36.716661754769355",
    lon: "142.2006819814913"
  },
  {
    lat: "-36.09082761357164",
    lon: "146.92256495840127"
  },
  {
    lat: "-37.855296766718176",
    lon: "145.05754798477014"
  },
  {
    lat: "-37.85005447688691",
    lon: "145.09176265240416"
  },
  {
    lat: "-37.870814610227356",
    lon: "145.13633372966947"
  },
  {
    lat: "-38.056858741781284",
    lon: "145.1468949228881"
  },
  {
    lat: "-37.90353923926913",
    lon: "145.1559336734485"
  },
  {
    lat: "-37.903754866870294",
    lon: "145.1552826319534"
  },
  {
    lat: "-37.90337434714781",
    lon: "145.15620695012547"
  },
  {
    lat: "-37.903938784030835",
    lon: "145.1562431190974"
  },
  {
    lat: "-37.825107297626445",
    lon: "144.9631383436528"
  },
  {
    lat: "-37.806585017664574",
    lon: "144.82325493193363"
  },
  {
    lat: "-37.71735867219732",
    lon: "144.65564040085152"
  },
  {
    lat: "-37.739602493782314",
    lon: "145.08487260555438"
  }
];
const branches = [
  "HQ",
  "Branch 1",
  "Branch 2"
];

const equipment = [
  "Compressor",
  "Telehandler",
  "Traffic Lights",
  "Generator",
  "Light Tower"
];

const randomNumber = () => Math.floor(Math.random() * 1010);
const randomBool = () => Math.random() < 0.5
const randomHundredNumber = () => Math.floor(Math.random() * 101)

export const seed = (db: MySubClassedDexie) => {
  const yes = Array.from(Array(17).keys()).map(x => {
      const isOnline = randomBool();
      const identifier = `TL-${randomNumber()}`
      const utilisation = randomHundredNumber()

      return db.devices.put({
        lat: locPool[x].lat,
        lon: locPool[x].lon,
        branch: branches[Math.floor(Math.random()*branches.length)],
        equipment: equipment[Math.floor(Math.random()*equipment .length)],
        id: x.toString(),
        name: identifier,
        status: isOnline ? "online" : "offline",
        type: "Mobile",
        online: true,
        battery: 50,
        shared: true,
        owner: null,
        util: utilisation,
      } as Device)
    }
  );
  Promise.allSettled(yes).then((results) => console.log(results));
}


export const eggs = 0;
