import type { Router } from "vue-router";
import router from "@/router";
import { useAuthStore } from "@/store/auth";

export const applyGuard =  (r: Router) => {
    r.beforeEach(async (to, from, next) => {
        const authStore = useAuthStore();

        if (!authStore.checked) {
            await authStore.session()
            authStore.checked = true
        }

        if (to.name == 'login' && authStore.userData) {
            await router.push({ name: 'overview' });
            next(false)

            return
        }

        if (to.name != 'login' && !authStore.userData) {
            await router.push({ name: 'login', query: {
                goto: to.fullPath
            } });
            next(false)
            return
        }

        next()
    })

}
