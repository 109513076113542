<script setup lang="ts">
import { ref } from "vue";
import AppSubMenu from "./AppSubMenu.vue";

const model = ref([
  {
    label: "Dashboards",
    icon: "pi pi-home",
    items: [
      {
        label: "Overview",
        icon: "pi pi-fw pi-home",
        to: "/"
      }
    ]
  },
  {
    label: "Trackers",
    icon: "pi pi-th-large",
    items: [
      {
        label: "Location",
        icon: "pi pi-fw pi-compass",
        items: [
          {
            label: "List",
            icon: "pi pi-fw pi-book",
            to: "/"
          },
          {
            label: "Vehicles",
            icon: "pi pi-fw pi-car",
            to: "/"
          },
          {
            label: "Generators",
            icon: "pi pi-fw pi-bolt",
            to: "/"
          },
          {
            label: "Towers",
            icon: "pi pi-fw pi-building",
            to: "/"
          }
        ]
      },
      {
        label: "Reporting",
        icon: "pi pi-chart-line pi-calendar",
        to: "/report"
      }
    ]
  },
  {
    label: "Management",
    icon: "pi pi-th-large",
    to: "/management/overview",
  }
]);
</script>

<template>
  <AppSubMenu :model="model" />
</template>

<style lang="scss" scoped></style>
