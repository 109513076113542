<script setup lang="ts">

</script>

<template>
  <Suspense>
    <router-view />
  </Suspense>
</template>

<style scoped></style>
