<script setup>
import { useLayout } from '@/layout/composables/layout';
import { ref } from "vue";
import { useAuthStore } from "@/store/auth";

const authStore = useAuthStore();

const { layoutState } = useLayout();
const display = ref(false);

const open = () => {
    display.value = true;
};
const close = () => {
    display.value = false;
};

</script>

<template>
    <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right" class="layout-profile-sidebar w-full sm:w-25rem">
        <div class="flex flex-column mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Welcome</span>
            <span class="text-color-secondary font-medium mb-5">{{ authStore?.userData?.identity?.traits?.name?.first }} {{ session?.identity?.traits?.name?.last }}</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-money-bill text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Billing</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-cog text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Settings</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-power-off text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Sign Out</span>
                            <p class="text-color-secondary m-0"></p>
                        </div>
                    </a>
                </li>


                <Dialog header="Dialog" v-model:visible="display" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p class="line-height-3 m-0"><pre>{{ JSON.stringify(session, null, "\t")}}</pre></p>
                    <template #footer>
                        <Button label="Ok" @click="close" icon="pi pi-check" class="p-button-outlined" />
                    </template>
                </Dialog>
                <Button label="Debug: User Session" icon="pi pi-external-link" style="width: auto" @click="open" />

            </ul>
        </div>
    </Sidebar>
</template>
