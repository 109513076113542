import { defineStore } from "pinia";
import { Configuration, FrontendApi } from "@ory/client";

export const oryBasePath = import.meta.env.VITE_ORY_KRATOS_URL;

export const Ory = new FrontendApi(
  new Configuration({
    basePath: oryBasePath,
    baseOptions: {
      withCredentials: true,
    }
  }),
)

export type User = {
    name: string,
    avatar: string,
}

export const useAuthStore = defineStore("auth", {
    state: () => ({
        userData: null,
        checked: false,
    }),

    actions: {
      async session() {
        await Ory.toSession()
          .then(({ data }) => {
            this.userData = data
          })
          .catch(async () => {
            console.log('[Ory] User has no session.');
          });
      },
      async login(id, pw, method = 'password') {
        const eggs = await Ory.createBrowserLoginFlow({
            //returnTo: oryBasePath
        })

        const rawResponse = await fetch(eggs.data.ui.action, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            // @ts-ignore
            csrf_token: eggs.data.ui.nodes[0].attributes.value,
            identifier: id,
            password: pw,
            method: method
          })
        });

        const content = await rawResponse.json();
          return {
              content,
              ok: rawResponse.ok
          }
      }
    },
  });

